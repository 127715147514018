/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GenerateHealthCertificateDto = {
    appointmentId: string;
    language: GenerateHealthCertificateDto.language;
};

export namespace GenerateHealthCertificateDto {

    export enum language {
        EN = 'en',
        DE = 'de',
    }


}

