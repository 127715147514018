/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CompletionStep = {
    type: CompletionStep.type;
    isComplete: boolean;
};

export namespace CompletionStep {

    export enum type {
        APPOINTMENT_DETAILS = 'appointment_details',
        CUSTOMER_PROFILE = 'customer_profile',
        CUSTOMER_ADDRESS = 'customer_address',
        PET_PROFILE = 'pet_profile',
        ANAMNESIS = 'anamnesis',
        ANESTHESIA_CONSENT = 'anesthesia_consent',
    }


}

