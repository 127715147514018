/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateDocumentDto } from '../models/CreateDocumentDto';
import type { FormModel } from '../models/FormModel';
import type { SignAnesthesiaConsentDto } from '../models/SignAnesthesiaConsentDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FormService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns FormModel
     * @throws ApiError
     */
    public documentControllerCompleteAnesthesiaConsent({
        requestBody,
    }: {
        requestBody: SignAnesthesiaConsentDto,
    }): CancelablePromise<FormModel> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v2/form/anesthesia-consent/complete',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns FormModel
     * @throws ApiError
     */
    public documentControllerSignAnesthesiaConsent({
        requestBody,
    }: {
        requestBody: SignAnesthesiaConsentDto,
    }): CancelablePromise<FormModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/form/anesthesia-consent/sign',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns FormModel
     * @throws ApiError
     */
    public documentControllerCreateAnamnesis({
        requestBody,
    }: {
        requestBody: CreateDocumentDto,
    }): CancelablePromise<FormModel> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v2/form/anamnesis/create',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
