/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FormModel } from '../models/FormModel';
import type { GenerateHealthCertificateDto } from '../models/GenerateHealthCertificateDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FormAdminService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns FormModel
     * @throws ApiError
     */
    public documentAdminControllerGenerateHealthCertificate({
        requestBody,
    }: {
        requestBody: GenerateHealthCertificateDto,
    }): CancelablePromise<FormModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/admin/form/health-certificate/generate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
