/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AppointmentCommentModel } from './AppointmentCommentModel';
import type { AppointmentStatusLogModel } from './AppointmentStatusLogModel';
import type { ClinicModel } from './ClinicModel';
import type { CustomerModel } from './CustomerModel';
import type { DeviceDetailsDto } from './DeviceDetailsDto';
import type { DoctorModel } from './DoctorModel';
import type { FileModel } from './FileModel';
import type { FormModel } from './FormModel';
import type { InstructionModel } from './InstructionModel';
import type { MedicalEventModel } from './MedicalEventModel';
import type { OptionalTreatments } from './OptionalTreatments';
import type { PaymentTransactionModel } from './PaymentTransactionModel';
import type { PetModel } from './PetModel';
import type { VisitModel } from './VisitModel';

export type AppointmentModel = {
    createdAt: string;
    clinicId: string;
    petId: string;
    customerId: string;
    comment: string;
    status: AppointmentModel.status;
    doctorId: string;
    date: string;
    reminded: boolean;
    reminded2: boolean;
    treatmentId: string;
    duration: number;
    source: AppointmentModel.source;
    type: AppointmentModel.type;
    extraInfo: any;
    wasDoctorSelected: boolean;
    visitReasonDetails: string;
    illnessDuration: string;
    instructionsUpdatedAt: string;
    commentsUpdatedAt: string | null;
    petDeceased: boolean;
    deviceDetails: DeviceDetailsDto | null;
    expireBeforeInHours: number | null;
    optionalTreatments: OptionalTreatments | null;
    visitReason?: AppointmentModel.visitReason;
    clinic: ClinicModel;
    pet: PetModel;
    customer: CustomerModel | null;
    doctor?: DoctorModel;
    payments: Array<PaymentTransactionModel>;
    files: Array<FileModel>;
    statusLogs: Array<AppointmentStatusLogModel>;
    instructions: Array<InstructionModel>;
    comments: Array<AppointmentCommentModel>;
    visit: VisitModel | null;
    medicalEvents: Array<MedicalEventModel>;
    forms: Array<FormModel>;
    isTelemedicine: boolean;
    nextTeamMemberEvents: Array<'team_member_created' | 'team_member_confirmed' | 'team_member_marked_as_missed' | 'team_member_cancelled' | 'team_member_updated' | 'team_member_marked_as_waiting' | 'team_member_started_treatment' | 'team_member_finished_treatment' | 'team_member_completed'>;
    warnings: Array<'treatment_is_late' | 'checkout_is_late'>;
    actualTreatmentTime: number;
    isComplete: boolean;
    isWelcomeVisit: boolean;
    isSurgery: boolean;
    isDirectSales: boolean;
    didCustomerCreateIt: boolean;
    isWalkin: boolean;
    isUpcoming: boolean;
    isAppointment: boolean;
    needsReview: boolean;
    isInPast: boolean;
    id: string;
};

export namespace AppointmentModel {

    export enum status {
        PENDING = 'pending',
        WAITING_PAYMENT = 'waiting_payment',
        EXPIRED = 'expired',
        APPROVED = 'approved',
        IN_WAITING_ROOM = 'in_waiting_room',
        IN_TREATMENT = 'in_treatment',
        CHECKING_OUT = 'checking_out',
        MISSED = 'missed',
        CANCELED = 'canceled',
        COMPLETED = 'completed',
    }

    export enum source {
        MYREX = 'myrex',
        REXOS = 'rexos',
        WALKIN = 'walkin',
        MYREX_WALKIN = 'myrex_walkin',
        REXAPP = 'rexapp',
        REXAPP_WALKIN = 'rexapp_walkin',
    }

    export enum type {
        APPOINTMENT = 'appointment',
        INTERNAL = 'internal',
        BLOCKER_EXPIRING = 'blocker_expiring',
        BREAK = 'break',
        MEETING = 'meeting',
    }

    export enum visitReason {
        GENERAL = 'general',
        WELCOME = 'welcome',
        ILLNESS = 'illness',
        GENERAL_AND_ILLNESS = 'general_and_illness',
        GENERAL_FIRST_VISIT = 'general_first_visit',
    }


}

