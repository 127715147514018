/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AppointmentModel } from './AppointmentModel';
import type { CustomerModel } from './CustomerModel';
import type { FileModel } from './FileModel';
import type { PetModel } from './PetModel';
import type { UserModel } from './UserModel';

export type FormModel = {
    createdAt: string;
    updatedAt: string;
    status: FormModel.status;
    type: FormModel.type;
    data: any;
    signedAt: string | null;
    userId: string | null;
    customerId: string | null;
    appointmentId: string;
    petId: string;
    fileId: string | null;
    customer: CustomerModel | null;
    user: UserModel | null;
    appointment: AppointmentModel | null;
    pet: PetModel | null;
    file: FileModel | null;
    id: string;
};

export namespace FormModel {

    export enum status {
        PENDING = 'pending',
        IN_PROGRESS = 'in_progress',
        COMPLETED = 'completed',
        SIGNED = 'signed',
        CANCELED = 'canceled',
    }

    export enum type {
        ANESTHESIA_CONSENT = 'anesthesia_consent',
        HEALTH_CERTIFICATE = 'health_certificate',
        ANAMNESIS = 'anamnesis',
    }


}

