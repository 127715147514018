/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdatePetDto = {
    id: string;
    name?: string;
    breed?: string;
    gender?: UpdatePetDto.gender;
    birthDate?: string;
    categoryId?: string;
    subCategoryId?: string;
    hasInsurance?: boolean;
    isNeutered?: boolean;
    isOutdoor?: boolean;
    alergyAndDisease?: string;
    medication?: string;
    chipNumber?: string;
    euPassNumber?: string;
    insuranceProvider?: string;
    insuranceIdNumber?: string;
    notes?: string;
    furColor?: string;
    isGermanOrigin?: boolean;
    isDangerous?: boolean;
    originCountry?: string | null;
};

export namespace UpdatePetDto {

    export enum gender {
        MALE = 'male',
        FEMALE = 'female',
    }


}

